<div class="login-body">
  <div class="login-container">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Login to SpendWyze Payment Request Gateway Demo Application</mat-card-title>
        </mat-card-header>

        <mat-card-content class="login-card-content">
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <mat-form-field>
              <input matInput placeholder="Email" formControlName="email">
            </mat-form-field>

            <mat-form-field>
              <input matInput type="password" placeholder="Password" formControlName="password">
            </mat-form-field>

            <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid">Login</button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>