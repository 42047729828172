import { Component } from '@angular/core';

@Component({
    selector: 'app-login',
    templateUrl: './email-sent.component.html',
    styleUrls: ['./email-sent.component.scss']
})
export class EmailSentComponent {

    constructor(
    ) { }

}
