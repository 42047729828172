import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { NotificationService } from '../0-shared/services/notification.sevice';
import { AuthService } from '../0-shared/_generated/backend';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    loginForm = this.fb.nonNullable.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]]
    });

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    async login() {
        try {
            this.validateForm();
            const { email, password } = this.loginForm.getRawValue();
            await firstValueFrom(this.authService.sendMagicLink({ email, password }));
            this.router.navigateByUrl('/email-sent');
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                    const errorMessage = error.error.message || error.message;
                    this.notificationService.showErrorSnackBar(errorMessage);
                } else {
                    console.error(error);
                    this.notificationService.showErrorSnackBar('Server error.');
                }
            } else {
                console.error(error);
                this.notificationService.showErrorSnackBar('Unexpected error.');
            }
        }
    }

    validateForm() {
        if (this.loginForm.invalid) {
            throw new Error('Invalid form field!');
        }
    }

}
