/**
 * sw-pr-demoapp-be
 * SpendWyze Payment Request API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface EmailAndPassword { 
    /**
     * The user's email address.
     */
    email: string;
    /**
     * The user's password.
     */
    password: string;
}