<div class="init-body">
    <mat-toolbar color="primary">
        <span class="left-content">DemoGO</span>
        <span class="fill-space"></span>
        <span class="right-content">
            <span>Logout in:&nbsp;</span><app-timer></app-timer>
            <button mat-icon-button (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button (click)="logout()">
                <mat-icon>logout</mat-icon>
            </button>
        </span>
    </mat-toolbar>

    <div class="init-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Initiate Payment Request</mat-card-title>
            </mat-card-header>

            <mat-card-content class="init-card-content">
                
                <mat-radio-group aria-label="Payment Request Mode" #modeSelector="matRadioGroup">
                    <mat-radio-button value="UNATTENDED" [checked]="true">Unattended</mat-radio-button>
                    <mat-radio-button value="PAYER_ATTENDED">Payer Attended</mat-radio-button>
                </mat-radio-group>

                <app-init-unattended *ngIf="modeSelector.value === 'UNATTENDED'"></app-init-unattended>
                <app-init-payer-attended *ngIf="modeSelector.value === 'PAYER_ATTENDED'"></app-init-payer-attended>

            </mat-card-content>
        </mat-card>
    </div>
</div>