<form [formGroup]="initUnattendedForm" (ngSubmit)="submit()">

  <div>
    <mat-form-field>
      <mat-label>Validity Date</mat-label>
      <input matInput [matDatepicker]="validityDatePicker" formControlName="validityDate">
      <mat-datepicker-toggle matIconSuffix [for]="validityDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #validityDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>Deadline</mat-label>
      <input matInput [matDatepicker]="deadlinePicker" formControlName="deadline">
      <mat-datepicker-toggle matIconSuffix [for]="deadlinePicker"></mat-datepicker-toggle>
      <mat-datepicker #deadlinePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div formGroupName="payee">
    <mat-form-field>
      <mat-label>Payee Account Number</mat-label>
      <mat-select formControlName="accountNumber">
        @for (account of accounts; track account) {
        <mat-option [value]="account.accountNumber">{{account.accountNumber}} ({{account.name}})</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div formGroupName="payer">
    <mat-form-field>
      <mat-label>Payer Account Number</mat-label>
      <input matInput formControlName="accountNumber" [matAutocomplete]="autoAccountNumbers">
      <mat-autocomplete #autoAccountNumbers="matAutocomplete">
        @for (account of accounts; track account) {
        <mat-option [value]="account.accountNumber">{{account.accountNumber}}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Payer Name</mat-label>
      <input matInput formControlName="name" [matAutocomplete]="autoNames">
      <mat-autocomplete #autoNames="matAutocomplete">
        @for (account of accounts; track account) {
        <mat-option [value]="account.name">{{account.name}}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field>
      <mat-label>Amount</mat-label>
      <input matInput formControlName="amount" type="number">
    </mat-form-field>

    <mat-slide-toggle formControlName="amountModifiable">Amount Modifiable</mat-slide-toggle>
  </div>

  <mat-form-field>
    <mat-label>Comment</mat-label>
    <input matInput formControlName="comment">
  </mat-form-field>

  <mat-card-actions>
    <button mat-flat-button color="primary" type="submit" [disabled]="initUnattendedForm.invalid">SUBMIT</button>
  </mat-card-actions>

</form>