import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../0-shared/services/notification.sevice';
import { Account, AccountService, PayerAttendedPaymentRequestTaskInput, PaymentRequestTaskService } from '../0-shared/_generated/backend';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-init-payer-attended',
    templateUrl: './init-payer-attended.component.html',
    styleUrl: './init-payer-attended.component.scss'
})
export class InitPayerAttendedComponent implements OnInit {

    accounts: Account[] = [];

    initPayerAttendedForm = this.fb.nonNullable.group({
        validityDate: ['', [Validators.required]],
        deadline: ['', [Validators.required]],
        payee: this.fb.nonNullable.group({
            accountNumber: ['', [Validators.required]]
        }),
        payer: this.fb.nonNullable.group({
            accountNumber: ['', [Validators.required]],
            name: ['', [Validators.required]]
        }),
        payerModifiable: [false, [Validators.required]],
        amount: [10, [Validators.required, Validators.min(1)]],
        amountModifiable: [false, [Validators.required]],
        comment: [''],
        commentModifiable: [false, [Validators.required]]
    });

    constructor(
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private accountService: AccountService,
        private paymentRequestTaskService: PaymentRequestTaskService
    ) { }

    async ngOnInit() {
        this.initPayerAttendedForm.controls.validityDate.setValue(moment().add(1, 'month').toISOString());
        this.initPayerAttendedForm.controls.deadline.setValue(moment().add(1, 'month').toISOString());

        this.accounts = await firstValueFrom(this.accountService.getAcounts());
        if (this.accounts[0]) {
            this.initPayerAttendedForm.controls.payee.controls.accountNumber.setValue(this.accounts[0].accountNumber);
        }

        this.setRequiredBasedOnModifiable(this.initPayerAttendedForm.controls.amountModifiable, this.initPayerAttendedForm.controls.amount);
        this.setRequiredBasedOnModifiable(this.initPayerAttendedForm.controls.payerModifiable, this.initPayerAttendedForm.controls.payer.controls.name);
        this.setRequiredBasedOnModifiable(this.initPayerAttendedForm.controls.payerModifiable, this.initPayerAttendedForm.controls.payer.controls.accountNumber);
    }

    async submit() {
        try {
            this.validateForm();
            const formValue = this.initPayerAttendedForm.getRawValue();

            const input: PayerAttendedPaymentRequestTaskInput = {
                ...formValue,
                validityDate: moment(formValue.validityDate).format('YYYY-MM-DD'),
                deadline: new Date(formValue.deadline)
            };

            // fix default values of empty fields
            if (input.comment === null || input.comment === '') {
                delete input.comment;
            }
            if (input.amount === null || input.amount === 0) {
                delete input.amount;
            }
            if (input.payer?.accountNumber === null || input.payer?.accountNumber === '') {
                delete input.payer.accountNumber;
            }
            if (input.payer?.name === null || input.payer?.name === '') {
                delete input.payer.name;
            }

            const response = await firstValueFrom(this.paymentRequestTaskService.initPayerAttended(input));
            if (response.error) {
                this.notificationService.showErrorSnackBar(`Error: ${response.error}`);
            } else {
                const link = `${environment.WEBAPP_BASE_URL}/init?token=${response.token}`;
                this.notificationService.showSnackBarWithLink(`Success! Id:  ${response.paymentRequestTaskId}`, link);
            }

        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                    const errorMessage = error.error.message || error.message;
                    this.notificationService.showErrorSnackBar(errorMessage);
                } else {
                    console.error(error);
                    this.notificationService.showErrorSnackBar('Server error.');
                }
            } else {
                console.error(error);
                this.notificationService.showErrorSnackBar('Unexpected error.');
            }
        }
    }

    validateForm() {
        if (this.initPayerAttendedForm.invalid) {
            throw new Error('Invalid form field!');
        }
    }

    private setRequiredBasedOnModifiable(modifiableControl: FormControl<boolean>, inputControl: FormControl) {
        modifiableControl.valueChanges.subscribe(modifiable => {
            if (modifiable) {
                inputControl.removeValidators(Validators.required);
            } else {
                inputControl.addValidators(Validators.required);
            }
            inputControl.updateValueAndValidity();
        });
    }

}
