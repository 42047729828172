import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../0-shared/services/notification.sevice';
import { Account, AccountService, PaymentRequestTaskService, UnattendedPaymentRequestTaskInput } from '../0-shared/_generated/backend';

@Component({
    selector: 'app-init-unattended',
    templateUrl: './init-unattended.component.html',
    styleUrl: './init-unattended.component.scss'
})
export class InitUnattendedComponent implements OnInit {

    accounts: Account[] = [];

    initUnattendedForm = this.fb.nonNullable.group({
        validityDate: ['', [Validators.required]],
        deadline: ['', [Validators.required]],
        payee: this.fb.nonNullable.group({
            accountNumber: ['', [Validators.required]]
        }),
        payer: this.fb.nonNullable.group({
            accountNumber: ['', [Validators.required]],
            name: ['', [Validators.required]]
        }),
        amount: [10, [Validators.required, Validators.min(1)]],
        amountModifiable: [false, [Validators.required]],
        comment: ['']
    });

    constructor(
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private accountService: AccountService,
        private paymentRequestTaskService: PaymentRequestTaskService
    ) { }

    async ngOnInit() {
        this.initUnattendedForm.controls.validityDate.setValue(moment().add(1, 'month').toISOString());
        this.initUnattendedForm.controls.deadline.setValue(moment().add(1, 'month').toISOString());

        this.accounts = await firstValueFrom(this.accountService.getAcounts());
        if (this.accounts[0]) {
            this.initUnattendedForm.controls.payee.controls.accountNumber.setValue(this.accounts[0].accountNumber);
        }
    }

    async submit() {
        try {
            this.validateForm();
            const formValue = this.initUnattendedForm.getRawValue();

            const input: UnattendedPaymentRequestTaskInput = {
                ...formValue,
                validityDate: moment(formValue.validityDate).format('YYYY-MM-DD'),
                deadline: new Date(formValue.deadline)
            };

            // fix default value of empty field
            if (input.comment === null || input.comment === '') {
                delete input.comment;
            }

            const response = await firstValueFrom(this.paymentRequestTaskService.initUnattended(input));
            if (response.error) {
                this.notificationService.showErrorSnackBar(`Error: ${response.error}`);
            } else {
                this.notificationService.showSuccessSnackBar(`Success! Id: ${response.paymentRequestTaskId}`);
            }

        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                if (error.status === 400) {
                    const errorMessage = error.error.message || error.message;
                    this.notificationService.showErrorSnackBar(errorMessage);
                } else {
                    console.error(error);
                    this.notificationService.showErrorSnackBar('Server error.');
                }
            } else {
                console.error(error);
                this.notificationService.showErrorSnackBar('Unexpected error.');
            }
        }
    }

    validateForm() {
        if (this.initUnattendedForm.invalid) {
            throw new Error('Invalid form field!');
        }
    }
}
