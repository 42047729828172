import { Component } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { NotificationService } from '../0-shared/services/notification.sevice';
import { AuthService, Configuration } from '../0-shared/_generated/backend';

@Component({
    selector: 'app-init',
    templateUrl: './init.component.html',
    styleUrl: './init.component.scss'
})
export class InitComponent {

    constructor(
        private backendConfiguration: Configuration,
        private notificationService: NotificationService,
        private authService: AuthService,
        private jwtHelperService: JwtHelperService,
        private router: Router
    ) { }

    async refresh() {
        try {
            const refreshToken = localStorage.getItem('refreshToken')!;
            this.backendConfiguration.apiKeys!['refreshtoken'] = refreshToken;
            const tokenPairWithMetaData = await firstValueFrom(this.authService.refreshTokens());
            this.backendConfiguration.accessToken = tokenPairWithMetaData.accessToken;

            localStorage.clear();
            localStorage.setItem('accessToken', tokenPairWithMetaData.accessToken);
            localStorage.setItem('refreshToken', tokenPairWithMetaData.refreshToken);
            const decodedToken = this.jwtHelperService.decodeToken(tokenPairWithMetaData.accessToken);

            const accessTokenExpiration = decodedToken.exp;
            localStorage.setItem('accessTokenExpiration', accessTokenExpiration);
            return;
        } catch (error: any) {
            this.notificationService.showErrorSnackBar(error.message);
        }
    }

    async logout() {
        localStorage.clear();
        this.router.navigateByUrl('/login');
    }

}
