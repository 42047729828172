import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

    countdown = '';

    timerInterval: any;

    constructor() { }

    ngOnInit(): void {

        // Initialize the countdown
        this.updateCountdown();

        // Start countdown interval
        this.timerInterval = setInterval(() => {
            this.updateCountdown();
        }, 1000);
    }

    ngOnDestroy(): void {
    // Unsubscribe from timer interval when component is destroyed
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    }

    updateCountdown() {
        const accessTokenExpiration = Number(localStorage.getItem('accessTokenExpiration'));
        const logoutTime = moment.unix(accessTokenExpiration);

        // Calculate remaining time until logout
        const currentTime = moment();

        if (currentTime.isAfter(logoutTime)) {
            this.countdown = '00:00:00';
            return;
        }

        const duration = moment.duration(logoutTime!.diff(currentTime));

        // Format duration as "hours:minutes:seconds"
        const hours = duration.hours().toString().padStart(2, '0');
        const minutes = duration.minutes().toString().padStart(2, '0');
        const seconds = duration.seconds().toString().padStart(2, '0');

        this.countdown = `${hours}:${minutes}:${seconds}`;
    }
}
