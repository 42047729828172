/**
 * sw-pr-demoapp-be
 * SpendWyze Payment Request API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PayerAttendedPaymentRequestTaskResponse { 
    /**
     * The unique identifier of the task.
     */
    paymentRequestTaskId?: string;
    /**
     * This token allows the Payer to access the UI where they can finalize this PaymentRequest.
     */
    token?: string;
    /**
     * The reason of the rejection.
     */
    error?: string;
}