import { Component, inject, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snack-bar-with-link',
    templateUrl: './snack-bar-with-link.component.html',
    styleUrl: './snack-bar-with-link.component.scss'
})
export class SnackBarWithLinkComponent {

    snackBarRef = inject(MatSnackBarRef);

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string; link: string }) { }

}
