import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { registerLocaleData } from '@angular/common';
import localeHu from '@angular/common/locales/hu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatOptionModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { environment } from '../environments/environment';
import { InitUnattendedComponent } from './init-unattended/init-unattended.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, BASE_PATH, Configuration } from './0-shared/_generated/backend';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { LoginComponent } from './login/login.component';
import { MagicLinkAuthComponent } from './magic-link-auth/magic-link-auth.component';
import { EmailSentComponent } from './sent/email-sent.component';
import { TimerComponent } from './timer/timer.component';
import { InitPayerAttendedComponent } from './init-payer-attended/init-payer-attended.component';
import { InitComponent } from './init/init.component';
import { SnackBarWithLinkComponent } from './snack-bar-with-link/snack-bar-with-link.component';

registerLocaleData(localeHu);

@NgModule({
    declarations: [
        AppComponent,
        InitUnattendedComponent,
        InitPayerAttendedComponent,
        LoginComponent,
        EmailSentComponent,
        MagicLinkAuthComponent,
        TimerComponent,
        InitComponent,
        SnackBarWithLinkComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NoopAnimationsModule,
        MatMenuModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatMomentDateModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatPaginatorModule,
        MatSnackBarModule,
        JwtModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatSortModule,
        MatSlideToggleModule,
        ApiModule.forRoot(() => new Configuration({ apiKeys: {} }))
    ],
    providers: [
        provideNativeDateAdapter(),
        { provide: MatSnackBarRef, useValue: {} },
        { provide: MAT_SNACK_BAR_DATA, useValue: {} },
        { provide: BASE_PATH, useFactory: () => environment.BACKEND_URL },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' }
        },
        FormsModule,
        {
            provide: JwtHelperService,
            useFactory: () => new JwtHelperService()
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
            provide: MatSnackBarRef,
            useValue: {}
        }, {
            provide: MAT_SNACK_BAR_DATA,
            useValue: {}
        },
        {
            provide: LOCALE_ID,
            useValue: 'hu-HU'
        },
        provideAnimationsAsync()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
