import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { InitComponent } from './init/init.component';
import { LoginComponent } from './login/login.component';
import { MagicLinkAuthComponent } from './magic-link-auth/magic-link-auth.component';
import { EmailSentComponent } from './sent/email-sent.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'magic-link-auth', component: MagicLinkAuthComponent },
    { path: 'email-sent', component: EmailSentComponent },
    { path: 'init', component: InitComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: 'init' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
