import { Inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarWithLinkComponent } from '../../snack-bar-with-link/snack-bar-with-link.component';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    constructor(
        public snackBar: MatSnackBar,
        public snackBarRef: MatSnackBarRef<NotificationService>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

    showSuccessSnackBar(message: string): void {
        this.snackBar.open(message, 'OK', { panelClass: ['snack-bar', 'success-snack-bar'] });
    }

    showErrorSnackBar(message: string): void {
        this.snackBar.open(message, 'CLOSE', { panelClass: ['snack-bar', 'error-snack-bar'] });
    }

    showSnackBarWithLink(message: string, link: string): void {
        this.snackBar.openFromComponent(SnackBarWithLinkComponent, { data: { message, link }, panelClass: ['snack-bar', 'success-snack-bar'] });
    }

    dismissSnackBar(): void {
        this.snackBar.dismiss();
    }
}
