/**
 * sw-pr-demoapp-be
 * SpendWyze Payment Request API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Account { 
    /**
     * The account number
     */
    accountNumber: string;
    /**
     * The name of the account's owner.
     */
    name: string;
}