import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom } from 'rxjs';
import { AuthService, Configuration } from '../0-shared/_generated/backend';
import { NotificationService } from '../0-shared/services/notification.sevice';

@Component({
    selector: 'app-magic-link-auth',
    templateUrl: './magic-link-auth.component.html',
    styleUrls: ['./magic-link-auth.component.scss']
})
export class MagicLinkAuthComponent implements OnInit {

    constructor(
        private notificationService: NotificationService,
        private backendConfiguration: Configuration,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private jwtHelperService: JwtHelperService
    ) { }

    async ngOnInit() {
        const magicLinkToken = this.route.snapshot.queryParams['token'];
        if (!magicLinkToken) {
            this.router.navigateByUrl('/login');
        }

        try {
            localStorage.clear();
            this.backendConfiguration.apiKeys!['magiclinktoken'] = magicLinkToken;
            const tokenPairWithMetaData = await firstValueFrom(this.authService.getTokens());
            this.backendConfiguration.accessToken = tokenPairWithMetaData.accessToken;

            localStorage.setItem('accessToken', tokenPairWithMetaData.accessToken);
            localStorage.setItem('refreshToken', tokenPairWithMetaData.refreshToken);
            const decodedToken = this.jwtHelperService.decodeToken(tokenPairWithMetaData.accessToken);

            const accessTokenExpiration = decodedToken.exp;
            localStorage.setItem('accessTokenExpiration', accessTokenExpiration);

            this.router.navigateByUrl('/');
            return;
        } catch (error: any) {
            this.notificationService.showErrorSnackBar(error.message);
            this.router.navigateByUrl('/login');
        }
    }
}
