/**
 * sw-pr-demoapp-be
 * SpendWyze Payment Request API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PayerAttendedPaymentRequestTaskInputPayer } from './payerAttendedPaymentRequestTaskInputPayer';
import { UnattendedPaymentRequestTaskInputPayee } from './unattendedPaymentRequestTaskInputPayee';

export interface PayerAttendedPaymentRequestTaskInput { 
    /**
     * The date till the transaction can be accepted or declined.
     */
    validityDate: string;
    /**
     * The date till the transaction should be accepted or declined.
     */
    deadline: Date;
    payee: UnattendedPaymentRequestTaskInputPayee;
    payer?: PayerAttendedPaymentRequestTaskInputPayer;
    /**
     * The flag specifies if the payer account can be modified or not.
     */
    payerModifiable: boolean;
    /**
     * The amount of the transaction.
     */
    amount?: number;
    /**
     * The flag specifies if the amount can be modified or not.
     */
    amountModifiable: boolean;
    /**
     * The comment of the transaction.
     */
    comment?: string;
    /**
     * The flag specifies if the comment can be modified or not.
     */
    commentModifiable: boolean;
}