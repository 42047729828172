export * from './account.service';
import { AccountService } from './account.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './paymentRequestTask.service';
import { PaymentRequestTaskService } from './paymentRequestTask.service';
export * from './swagger.service';
import { SwaggerService } from './swagger.service';
export const APIS = [AccountService, AuthService, HealthService, PaymentRequestTaskService, SwaggerService];
