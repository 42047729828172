<div class="email-sent-body">
  <div class="email-sent-container">
    <div fxLayout="row" fxLayoutAlign="center center" class="content">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Magic Link Sent</mat-card-title>
        </mat-card-header>
        <mat-card-content class="email-sent-card-content">
          <p>Check your email inbox for the magic link.</p>
          <p>Click on the magic link to log in to your account.</p>
          <p>If you haven't received the email, please check your spam folder.</p>
          <p>If you still haven't received the email after a few minutes, you can <a href="/login">request another magic link by logging in again</a>.</p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>