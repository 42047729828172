/**
 * sw-pr-demoapp-be
 * SpendWyze Payment Request API.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TokenPairWithMetaData { 
    accessToken: string;
    refreshToken: string;
    type: TokenPairWithMetaData.TypeEnum;
    /**
     * Related to access token.
     */
    expiresAt: Date;
}
export namespace TokenPairWithMetaData {
    export type TypeEnum = 'Bearer';
    export const TypeEnum = {
        Bearer: 'Bearer' as TypeEnum
    };
}