import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Configuration } from '../0-shared/_generated/backend';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private backendConfiguration: Configuration,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            this.backendConfiguration.accessToken = accessToken;
            return true;
        }

        this.router.navigateByUrl('/login');
        return false;
    }
}
